import React from 'react';
import App from './App';
import { Route, Switch } from 'react-router-dom';
import { ARView } from './components';
import NotSupported from './components/NotSupported';
import AdBlockDetect from 'react-ad-block-detect';
import DetectedAdBlock from './components/DetectedAdBlock'

export default class MainTemplate extends React.Component {
	state = {
		isSupported: true,
		adBlockEnabled: false
	}
	constructor(props) {
		super(props)
	}

	iOSversion() {
		if (/iP(hone|od|ad)/.test(navigator.platform)) {
			// supports iOS 2.0 and later: <https://bit.ly/TJjs1V>
			var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
			return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
		}
	}


	componentWillMount() {

		//console.log(navigator.userAgent)
		var iosVersion = () => this.iosVersion();
		var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
		//console.log("vendor and platform", navigator.vendor, /Apple/.test(navigator.vendor), navigator.platform);
		var isSafari = false;
		var ua = navigator.userAgent.toLowerCase(); 
		if (ua.indexOf('safari') != -1) { 
			if (ua.indexOf('version') === -1) {
				isSafari = false; // another browser
			} else {
				isSafari = true; // Safari
			}
		}
		if (/iPad|iPhone/.test(navigator.userAgent) && !isSafari ||
			/iPad|iPhone/.test(navigator.userAgent) && iosVersion[0] < 11 ||
			/iPad|iPhone/.test(navigator.userAgent) && iosVersion[0] == 11 && iosVersion[1] < 2 ||
			navigator.userAgent.match(/Android/i) && !isChrome &&
			navigator.userAgent.match(/Android/i) && navigator.userAgent.toLowerCase().indexOf('firefox') === -1 ||
			navigator.userAgent.match(/Windows/i) && !isChrome &&
			navigator.userAgent.match(/Windows/i) && navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
			//console.log("entered");
			this.setState({ isSupported: false })
		}
		else {
			this.setState({ isSupported: true });
			// this.state.isSupported = true;
		}
		
	}

	componentWillUnmount(){
	}

	// <AdBlockDetect><DetectedAdBlock blocked={() => this.setState({ adBlockEnabled: true })} /></AdBlockDetect>

	render() {
		return (

			<div className="mainTemplate">
				<React.Fragment>
					{!this.state.adBlockEnabled &&
						<React.Fragment>
							{!this.state.isSupported ? <NotSupported /> :
								<Switch>
									<Route exact path='/' component={ARView} />
								</Switch>
							}
						</React.Fragment>
					}
				</React.Fragment>
			</div>

		)
	}
}