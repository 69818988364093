import React, { Component } from 'react';
import Router from './Router.js';



class MainRoute extends Component {

    render() {
        return (
            <React.Fragment>
                <Router />
            </React.Fragment>
        );
    }
}

export default MainRoute;
