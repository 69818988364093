const AFRAME = window.AFRAME;
const THREE = window.THREE;
AFRAME.registerComponent('ar-marker', {

  schema: {
    target: { type: 'selector', default: null },
    srcModel: { type: "string", default: "" },
    srcImage: { type: "string", default: "" }
  },

  dependencies: ['sound', 'cursor', 'emitevents'],

  init: function() {

    this.el.setAttribute('cursor', { rayOrigin: AFRAME.utils.device.isMobile() ? 'entity' : 'mouse' });
    this.isSoundLoaded = false
    // //console.log("id", this.el.id)
    this.targetEl = this.data.target;
    if (this.targetEl) {
      this.targetEl.addEventListener('click', this.onMarkerClick.bind(this), true);
    }
    this.el.object3D.visible = false;
    this.el.addEventListener('markerFound', this.onMarkerFound.bind(this), true);
    this.el.addEventListener('markerLost', this.onMarkerLost.bind(this), true);

    this.el.addEventListener('sound-loaded', this.onSoundLoaded.bind(this), true);
    this.el.addEventListener('sound-ended', this.onSoundEnded.bind(this), true);
  },

  onSoundEnded(e) {
    //console.log('sound ended');
    this.el.emit('onSoundEndedMarker', { el: this.el, });
  },

  onSoundLoaded(e) {
    //console.log('sound loaded');
    this.isSoundLoaded = true;
    this.el.emit('onSoundLoadedMarker', { el: this.el, });
  },

  onMarkerFound(e) {
    var markerId = this.el.id;
    // if(this.data.srcModel && this.targetEl)
    // var modelEl = document.querySelector()
    this.targetEl.setAttribute("src", this.data.srcModel);
    this.el.object3D.visible = true;
    this.el.emit('onMarkerFound', { el: this.el, targetEl: this.targetEl, srcModel: this.data.srcModel });
  },

  onMarkerLost(e) {
    this.el.object3D.visible = false;
    this.targetEl.setAttribute("src", "");
    // modelEl.object3D.visible = false;    
    // this.targetEl.setAttribute("src", "");
    this.el.emit('onMarkerLost', { el: this.el, srcModel: this.data.srcModel  });
  },

  onMarkerClick(e) {
    var markerId = this.el.id;
    this.el.emit('onMarkerClick', { el: this.el, targetEl: this.targetEl, });
  },

  tick(){
  }
});
