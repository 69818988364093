import React, { Component } from 'react'

export default class NotSupported extends Component {
    constructor(props) {
        super(props)
        
    }

    render() {
        const ops = {
            browserNotSupported_1: 'Per poter iniziare l’esperienza utilizza il browser chrome o firefox.',
            osNotSupported: 'Ci dispiace, ma il tuo smartphone non supporta la tecnologia usata.',
            browserNotSupported_2: 'Per poter iniziare l’esperienza utilizza il browser safari.'

        }
        var text = ops.osNotSupported;
        var isSafari = false;
		var ua = navigator.userAgent.toLowerCase(); 
		if (ua.indexOf('safari') != -1) { 
			if (ua.indexOf('chrome') > -1 || ua.indexOf('crios') > -1) {
				isSafari = false; // Chrome
			} else {
				isSafari = true; // Safari
			}
		}
        if(/iPad|iPhone/.test(navigator.userAgent) && !isSafari ){
            text = ops.browserNotSupported_2;
        }

        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

        if(navigator.userAgent.match(/Windows/i) && navigator.userAgent.toLowerCase().indexOf('firefox') === -1 &&
            navigator.userAgent.match(/Windows/i) && !isChrome ||
            navigator.userAgent.match(/Android/i) && !isChrome &&
            navigator.userAgent.match(/Android/i) && navigator.userAgent.toLowerCase().indexOf('firefox') === -1){
            text = ops.browserNotSupported_1;
        }

        
        
        return (
            <div className='not_supported'>
               <p className='text_notsupported'>
                 Ciao! ☺️<br />
                {text}
            </p>
            </div>
        )
    }
}
