/* global AFRAME */
//This component creates an asset for an entity, allows the dynamic switch of asset source,
//but instead of doing like the aframe-asset-on-demand (which just replaces the src attribute without removing the associated tag)
//this component removes the tag entirely therefore preventing caching issues ()
//
if (typeof AFRAME === 'undefined') {
  throw new Error('Component attempted to register before AFRAME was available.');
}

AFRAME.registerComponent('item-on-demand', {

    schema: {
        htmlElement: {
          default: 'a-asset-item',
          type: 'string'
        },
        src: {
            default: '',
            type: 'string'
        },
        component: {
          default: 'sound',
          type: 'string'
        },
        componentAttr: {
          default: 'src',
          type: 'string'
        }
    },
    init(){
      this.loadAsset();
      // //console.log('component', this.data.component)
      this.currentSrc = this.data.src;
      this.data.el = this.el;
    },
    loadAsset(){
      // if(el)
      //   //console.log('element', el)
      // a-assets element
      var el;
      var assetBucket = document.querySelector("a-assets");
      // new asset model
      var src = this.currentSrc;
      var newAsset = document.createElement(this.data.htmlElement);
      newAsset.setAttribute("id", this.el.id + "-asset");
      newAsset.setAttribute("preload", 'auto');
      newAsset.setAttribute("crossOrigin", "anonymous");
      newAsset.setAttribute("src", this.data.src);
      if(this.data.component === 'sound')
        newAsset.setAttribute("response-type", 'arraybuffer');
      //add new asset to assetBucket
      this.assetModel = assetBucket.appendChild(newAsset);
      // //console.log('new asset', this.assetModel)
      // //console.log('asset model ', this.assetModel)
      // https://github.com/aframevr/aframe/blob/master/docs/components/sound.md
      if(this.data.component === "sound")
        this.assetModel.addEventListener("sound-loaded", this.attachAsset(el, this.assetModel.id, "sound"));
      else
        this.assetModel.addEventListener("loaded", this.attachAsset(el, this.assetModel.id, "not sound"));
        // this.el.setAttribute(this.data.attribute, this.data.componentAttr, "#" + this.assetModel.id );
    },
    //attaches asset to the aframe entity
    attachAsset(el, id, type){
      //console.log('assets loaded aframe', type)
      this.el.setAttribute(this.data.component, this.data.componentAttr, "#" + this.assetModel.id );
      if(this.data.component === 'sound')
        this.el.setAttribute(this.data.component, 'autoplay', 'false' );
      // this.el.setAttribute(this.data.component, this.data.componentAttr, "#" + id );
     
    },
    detachAsset(){
      this.el.setAttribute(this.data.component, this.data.componentAttr, '' );
      this.assetModel.removeEventListener("load", this.attachAsset);
      this.assetModel.removeEventListener("error", this.detachAsset);
      this.removeAsset();
      this.el.sceneEl.systems.material.clearTextureCache()
    },
    //removes from the DOM
    removeAsset(){
      // //console.log('removed')
      var element = document.querySelector('#' +  this.assetModel.id);
      //console.log('element ' + '#' +  this.assetModel + '-asset', element)
      if(element !== null && element !== undefined)
        element.parentNode.removeChild(element);
    },
    updateSrcAssetModel(src){
      this.assetModel.setAttribute("src", src);
      this.currentSrc = src;
    },
    reloadAsset(el) {
        // Detach Asset and try again in 1 Second
        this.detachAsset();
        setTimeout(this.loadAsset(), 3000);
    },
    //every 1 second checks for any differences in src, if so reloads the asset
    //src attribute is updated by the react logic
    update(){
      if( this.data.src !== this.currentSrc ){
        this.el.object3D.visible = false;
        this.updateSrcAssetModel(this.data.src);
        // //console.log(this.el)
        // this.currentSrc = this.data.src;
        this.reloadAsset(this.el);
        setTimeout(() => {
          console.log("changed src item");
          if(this.data.src !== "" )
            this.el.object3D.visible = true;
         }, 100);
      }
    },
    remove(){
      //console.log('asset removed', this.assetModel)
      this.assetModel.removeEventListener("sound-loaded", this.attachAsset);
      this.removeAsset();
    }
});
