import React from 'react';
import _ from 'lodash';



import { withStyles } from '@material-ui/core/styles';


const AFrameMarkerField = ({ id, key, name, content, arPos, arRot, arScale, srcPatternAssetUrl, srcImageAssetUrl,  srcAudioAssetUrl, arModelScaleStr, arModelPosStr, srcModelAssetUrl, arModelRotStr, arModelAnim }) => (
 <a-entity>
    <a-anchor
      id={id} 
      key={key} 
      preset="custom" 
      url={srcPatternAssetUrl} 
      type="pattern"
      ar-marker={"target: #" + id + "-model; srcModel: " + srcModelAssetUrl + "; srcImage: " + srcImageAssetUrl}
    >
      <a-gltf-model
        id={id + "-model"}
        position={arPos}
        rotation={arRot}
        scale={arScale}
        animation__idle={arModelAnim}>
      </a-gltf-model>
    </a-anchor> 
   </a-entity>
  // <div>
  //   <p>Ciao</p>
  // </div>
);

const styles = {
  // latLng: {
  //   fontWeight: 700
  // }
}

export default withStyles(styles)(AFrameMarkerField);
