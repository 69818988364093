import React, { Component } from 'react'

class DetectedAdBlock extends Component {
    constructor(props) {
        super(props)

    }

    componentWillMount() {
        this.props.blocked();
    }

    render() {

        return (
            <div className='not_supported'>
                <p className='text_notsupported'>
                    Per continuare a navigare su questo sito disattiva adblock.
                </p>
            </div>
        )
    }
}

export default DetectedAdBlock;
