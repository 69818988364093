import React, { Component } from 'react'
import "./css/LandingPage.scss";

export default class LandingPage extends Component {

    state = {
      clicked: false
    }

    constructor(props) {
        super(props)
    }


    componentDidMount(){
      let landingPage = document.querySelector("#landingPage");
      landingPage.addEventListener("click", this._onClick);
      window.addEventListener("resize", () => {
        if(!this.state.clicked){
          let background = document.querySelector("#background");
          if(window.innerWidth <= 690){
            background.setAttribute("src", "/assets/landingPage/sfondo-mobile-07.svg");
          }
          else{
            background.setAttribute("src", "/assets/landingPage/sfondodesk-01.png");
          }
        }
      });
    }

    _onClick = () => {
      this.setState({
        clicked: true
      });
      this.props.closeCover();
    }

    componentWillUnmount(){
      window.removeEventListener("resize", () => {});
      let landingPage = document.querySelector("#landingPage");
      landingPage.removeEventListener("click", this._onClick);
    }

    render(){
      return (
        <React.Fragment>
          <div id="landingPage">
            <img id="background" class="img-fluid" width="320" height="568" src="/assets/landingPage/sfondodesk-01.png" crossOrigin="anonymous"/>

            <img id="logo" src="/assets/landingPage/scala-02.png" crossOrigin="anonymous"/>
            <div id="text1" class="text">
              <div>Frame the marker on Scala paper model</div>
            </div>
            <div id="text2" class="text">
              <div>Click anywhere to continue</div>
            </div>
            <img id="arms" src="/assets/landingPage/braccia-03.png" crossOrigin="anonymous"/>
            
            <img id="device-marker" src="/assets/landingPage/device-marker-04-04.png" crossOrigin="anonymous"/>
          </div>
          <div id="bottom-bar"></div>
        </React.Fragment>
      );
    }

}