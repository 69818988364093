import React from 'react';
import _ from 'lodash';
import './css/ARView.scss';
import "./aframe/aframe-components";

// import { AframeRenderer, Marker } from "react-web-ar";
import { AFrameMarkerField, LandingPage } from "."
import axios from "axios";

const JSON_CONFIGURATION_FILE = "/markers.json";

class ARView extends React.Component {
  state = {
    markers: [],
    markerFound: false,
    markerFoundId: null,
    markerEl: null,
    markersRef: {},
    cameraPermissions: "prompt",
    mounted: false,
    isPortrait: window.matchMedia("(orientation: portrait)").matches,
    showLandingPage: true,
    isLoadingCamera: true
  }

  ///////////////////////////////////
  constructor(props) {
    super(props);
    this._getMarker();
    this.sceneEl = null;
    this.cursorEl = null;

    this.onMarkerFound = this._onMarkerFound.bind(this);
    this.onMarkerLost = this._onMarkerLost.bind(this);
    this.onCursorFound = this._onCursorFound.bind(this);
    this.onCursorLost = this._onCursorLost.bind(this);
    this.closeCover = this._closeCover.bind(this);


  }

  ///////////////////////////////////
  componentDidMount() {
    this.setState({
      mounted: true
    });
    console.log("ar view")
    this._loadScene();
    document.body.classList.add("body-scene");
    document.body.classList.remove("body-default");
    this.sceneEl = document.querySelector("a-scene");
    this.cursorEl = document.querySelector("#cursor");
    // this.setScreenOrientation();
    if (this.cursorEl) {
      this.cursorEl.addEventListener("onCursorFound", this.onCursorFound);
      this.cursorEl.addEventListener("onCursorLost", this.onCursorLost);
      window.matchMedia("(orientation: portrait)").onchange = () => {
        // console.log("changed");
        this.setState({
          isPortrait: window.matchMedia("(orientation: portrait)").matches
        });
      }

    }
    var alertCustom = window.alert;
    window.alert = function () { };
    // var askPermissions = navigator.mediaDevices.getUserMedia;
    // navigator.mediaDevices.getUserMedia = () => {};

    if (/iPhone|Mac/i.test(navigator.userAgent)) {

      if (window.location.href.indexOf('ar') > 0) {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            document.cookie = 'camera = 1'
          })
          .catch((err) => {
            console.log(err)
            document.cookie = 'camera = 0';
            alertCustom('ricarica e accetta permessi fotocamera per continuare esperienza')
          })
      }
    }
    else {
      navigator.permissions.query({ name: "camera" })
        .then((permissionObj) => {
          if (permissionObj) {
            document.cookie = 'camera = 1'
            console.log(permissionObj.state);

            if (permissionObj.state === "denied") {
              document.cookie = 'camera = 0';

              console.error("permissions denied");
              alertCustom('ricarica e accetta permessi fotocamera per continuare esperienza');
            }
            permissionObj.onchange = () => {
              if (permissionObj.state === "denied") {
                document.cookie = 'camera = 0';
  
                console.error("permissions denied");
                alertCustom('ricarica e accetta permessi fotocamera per continuare esperienza');
              }
            };
          }
        });
    }


    window.addEventListener("arjs-video-loaded", () => {
      this.setState({
        isLoadingCamera: false
      });
    });
  }
  ///////////////////////////////////  
  componentWillMount() {
    var rootContainer = document.querySelector("#root");
    if (rootContainer) {
      rootContainer.classList.add("ar");
      rootContainer.classList.add("a-scene");

    }
  }
  ///////////////////////////////////
  componentWillUnmount() {
    this.setState({
      mounted: false
    });
		window.removeEventListener("arjs-video-loaded", () => {});
    var videoTag = document.querySelector("video");
    if (videoTag)
      videoTag.parentNode.removeChild(videoTag);
    if (this.sceneEl) {
      this.sceneEl.removeEventListener("onMarkerFound", this.onMarkerFound);
      this.sceneEl.removeEventListener("onMarkerLost", this.onMarkerLost);
    }

    document.body.classList.add("body-default");
    document.body.classList.remove("body-scene");
    clearTimeout(this.timeout);
    // axios.abort();
  }

  ///////////////////////////////////
  componentDidUpdate(prevProps, prevState) {
  }

  ///////////////////////////////////
  _onMarkerFound = (e) => {
      const markerEl = e.detail.el;

      this.setState({ markerEl: markerEl });
      // markerEl.object3D.visible = false;
        markerEl.object3D.visible = true;
        // const targetEl = this.state.markersRef[markerEl.id];
        const srcModel = e.detail.srcModel;
        // const model = targetEl.ar.model;
        //this.onCursorFound();
        this.setState({
          markerFound: true,
          markerFoundId: markerEl.id
        });
  }

  ///////////////////////////////////
  _onMarkerLost = (e) => {
    console.log('lost')
    this.setState({
      markerFound: false,
      markerFoundId: null
    });

  }

  ///////////////////////////////////
  _onCursorFound = (e) => {

  }

  ///////////////////////////////////
  _onCursorLost = (e) => {

  }


  ///////////////////////////////////
  // _getMarkers = () => {
  //   axios
  //     .get("https://node.sasalasabolare.it/api/markers?filter={"where":{"tag": "SOMENAME"}}")
  //     .then(response => {
  //       if(this.state.mounted){
  //         const data = response.data;
  //         const markers = _.get(data, "markers", null);
  //         if (markers) {
  //           var array = [];
  //           for (let key in markers) {
  //             array.push(markers[key])
  //           }
  //           this.setState({
  //             markers: array,
  //             markersRef: _.keyBy(array, "id")
  //           })
  //         }
  //       }
  //     })
  //     .catch(error => { console.error(error) });
  // }

  
  ///////////////////////////////////
  _getMarker = () => {
    axios
      .get("https://node.sasalasabolare.it/api/markers/5db1bd82d702cc001952bbeb")
      .then( (response) => {
        if(this.state.mounted){
          console.log("response", response);
          const marker = response.data;
          let array = [];
          array.push(marker);
          this.setState({
            markers: array,
            markersRef: _.keyBy(array, "id")
          });
        }
      })
      .catch( (error) => {
        console.error(error);
      })
  }
  ///////////////////////////////////
  _canEdit = () => this.props.mode === 'edit' || this.props.mode === 'create';

  ////////////////////////////////////////
  //in edit/create mode we refer form data, in show mode we refer to record data!
  // _getData = () => {
  //   const { record = {}, form = { [REDUX_FORM_NAME]: {} } } = this.props;
  //   return this._canEdit() ? (form[REDUX_FORM_NAME].values || {}) : record;
  // }

  ///////////////////////////////////
  _loadSceneMarkers = (data) => {
    // console.log("markers", data)
  }




  ///////////////////////////////////
  //Scene only: loads markers and portals assets
  _loadScene = () => {
    const { markers } = this.props;
    this._loadSceneMarkers(markers);
  }




  ///////////////////////////////////
  _onAssetsLoaded = (loaded) => {
    //console.log('loaded', loaded);
    this.setState({ assetsLoaded: true });
  }



  ///////////////////////////////////
  _renderObjects = (type) => {
    if (this.state[type].length > 0) {
      return this.state[type].map(obj => {
        
        switch (type) {
          case 'markers':
            console.log("marker", obj);
            const { id = "", name = "", ar = {}, srcModel = "", srcPattern = "" } = obj.config;

            const scale = ar.model.scale;
            const rotation = ar.model.rotation;
            const position = ar.model.position;
            const animation = ar.model.animation ? ar.model.animation : "";
            let arPos = Object.values(ar.model.scale).join(' ');
            return (
              <AFrameMarkerField
                id={id}
                key={id}
                name={name}
                arPos={position}
                arRot={rotation}
                arScale={scale}
                srcPatternAssetUrl={srcPattern}
                srcAudioAssetUrl={""}
                arModelScaleStr={""}
                arModelPosStr={""}
                srcModelAssetUrl={srcModel}
                arModelRotStr={""}
                arModelAnim={animation}
                content={"model"}
              />
            );
          default:
            return null;
        }
      });
    }
  }

  _closeCover = () => {
    this.setState({
      showLandingPage: false
    });
  }

  ///////////////////////////////////  
  render() {
    const isPortrait = this.state.isPortrait;
    // console.log("permissionsssss", this.state.cameraPermissions);
    const cameraPermissions = this.state.cameraPermissions;
    return (
      <React.Fragment>
            { this.state.showLandingPage &&
              <LandingPage closeCover={this.closeCover}/>
            }
            { this.state.isLoadingCamera &&
              <div class="spinner-border text-light loader" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            }
            { !this.state.showLandingPage &&
              <a-scene
                id="main-scene"
                embedded
                arjs="sourceType: webcam; debugUIEnabled: false; patternRatio: 0.9; detectionMode: mono_and_matrix; matrixCodeType: 3x3_HAMMING63; maxDetectionRate: 500"
                ar-utils
                vr-mode-ui={false}
                className="a-scene"
                renderer="antialias: true; colorManagement: true; sortObjects: true; physicallyCorrectLights: true; "
              >
                <a-assets id="assets" timeout={10000}>
                  <a-mixin id="cursor-mixin"
                    material="shader: flat; color: #0A283E; transparent: true; opacity: 0"
                    geometry="primitive: cylinder; segmentsRadial: 6; segmentsHeight: 1; radius: 0.4; openEnded: true"
                    outline="color: #0A283E; opacity: 1; visible: true; tickness: 48"
                  ></a-mixin>
                </a-assets>
                <a-entity 
                  id="light-ambient"
                  light="type: ambient; intensity: 3;"></a-entity>

                <a-entity id="markers">
                  {this._renderObjects("markers")}
                </a-entity>


                <a-entity id="rig"
                  position="0 0 0">
                  <a-entity camera="fov: 70; aspect: 1.6"></a-entity>
                </a-entity>
              </a-scene>
            }
        

      </React.Fragment>
    );
  }
}



export default ARView;